<template>
  <div>
    <div class="container-content">
      <div>
        <v-form ref="supplierDetails">
          <v-text-field
            label="Supplier Name"
            outlined
            dense
            color="#473068"
            v-model="form.supplierName"
            :rules="[rules.supplierNameRequired, rules.max255]"
          ></v-text-field>

          <v-text-field
            ref="address"
            label="Address Line 1"
            outlined
            dense
            color="#473068"
            v-model="form.addressLine1"
            :rules="[rules.max1000]"
          ></v-text-field>

          <v-text-field
            ref="address"
            label="Address Line 2"
            outlined
            dense
            color="#473068"
            v-model="form.addressLine2"
            :rules="[rules.max1000]"
          ></v-text-field>

          <v-text-field
            ref="city"
            label="City"
            outlined
            dense
            color="#473068"
            v-model="form.city"
            :rules="[rules.max1000]"
          ></v-text-field>

          <v-text-field
            ref="state"
            label="State/Province/Region"
            outlined
            dense
            color="#473068"
            v-model="form.state"
            :rules="[rules.max1000]"
          ></v-text-field>

          <v-text-field
            ref="zip"
            label="ZIP / Postal Code"
            outlined
            dense
            color="#473068"
            v-model="form.postalCode"
            :rules="[rules.max200]"
          ></v-text-field>

          <v-autocomplete
            v-model="form.country"
            clearable
            :items="countries"
            item-text="country"
            item-value="country"
            label="Country"
            single-line
            outlined
            dense
          ></v-autocomplete>
        </v-form>
      </div>
        <div class="certificates-container">
          <div class="certificates-header">
            <div>Certificates:</div>
            <div class="certificates-add-btn" @click="addNewCertificateWindow(true)">
              <v-icon style="color: white;">add</v-icon>
            </div>
          </div>
          <div
            v-if="!form.certificates || !form.certificates.length"
            class="certificates-details" >
            <div class="subtitle_data">No certificates</div>
          </div>
          <div
            v-else
            class="certificates-details certificates-details-flex">
            <div
              v-for="certificate in form.certificates"
              :key="certificate.id"
              class="single-certificate"
            >
              <md-card-media v-if="!certificate.sendToServer">
              <embed
                class="centered-and-cropped"
                v-if="isSupportedFileType(certificate.upload_name)"
                :src="
                  `${baseUrl}/api/inquiries/other_supplier_certificate_show/${certificate.upload_name}`
                "
                :type="certificate.mime_type"
              />
              <div v-else>
                <div
                  class="font-weight-bold not-supported-file subtitle_data"
                >
                  {{ certificate.upload_name }}
                </div>
                <div class="subtitle_data">
                  Please download this file to view.
                </div>
              </div>
            </md-card-media>
            <md-card-media v-else>
              <embed
                class="centered-and-cropped"
                :src="certificate.file.base64"
                :type="certificate.type"
              />
            </md-card-media>
            <div>
              <div><truncate-hovering :text="certificate.caption" :length="10" /></div>
              <div class="certificate-delete" @click="deleteCertificate(certificate)"><v-icon style="color: #EA7A66;">delete</v-icon></div>
            </div>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <md-button
            class="save-btn"
            @click="createOtherSupplier"
            :disabled="saving"
            >Save
          </md-button>
        </div>
    </div>
    <popup-window
      :active="certificatesModel"
      title="Add new certificate"
      @popup-close="addNewCertificateWindow(false)"
      @popup-back="addNewCertificateWindow(false)"
    >
      <div v-if="certificatesModel" class="certificate-window">
        <v-form ref="certificateDetails">
          <v-text-field
            label="Caption"
            outlined
            dense
            color="#473068"
            v-model="addingCertificateData.caption"
            :rules="[rules.captionRequired, rules.max255]"
          ></v-text-field>
            <input
              type="file"
              ref="images"
              :accept="acceptedFileTypes"
              @change="removeFileError()"
              required
            />
          <span id="file-error" class="file-error">Please select a file</span>
        </v-form>
        <div class="btn-container">
          <md-button class="save-btn" @click="addNewCertificate">Add</md-button>
        </div>
      </div>
    </popup-window>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import countryList from "country-list";
import utils from "../../utils";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import { validFilesSize } from "../../utils/file-size";

export default {
  name: "BomOtherSupplier",
  components: {},

  data: () => ({
    countries: [],
    form: {
      supplierName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      certificates: [],
    },
    certificatesModel: false,
    showOptionBtn: true,
    addingCertificateData: {
      caption: "",
    },
    saving: false,
    acceptedFileTypes: utils.acceptedFileTypes,
    rules: {
      supplierNameRequired: (value) => !!value || "Supplier name is required",
      captionRequired: (value) => !!value || "Caption is required",
      max1000: (value) =>
        value.length <= 1000 || "Address should have less than 1000 characters",
      max255: (value) =>
        value.length <= 255 || "Must be no more than 255 characters",
      max200: (value) =>
        value.length <= 200 || "Must be no more than 200 characters",
    },
  }),

  props: {
    inquiryId: {
      type: [String, Number],
      required: true,
    },
    otherSupplierData: {
      type: Object,
      required: true,
    },
    baseUrl: {
      type: String,
      default: conf.default.server.host,
    },
  },

  watch: {
    otherSupplierData: {
      handler: function(value) {
        this.form = value;
      },
      deep: true,
      immediate: true,
    },
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  mounted() {
    const arr = countryList.getData();
    const obj = Object.entries(arr);

    for (let i = 0; i < obj.length; i++) {
        this.countries.push({ id: obj[i][0], country: obj[i][1].name })
    }
  },
  methods: {
    closeWindow() {
      this.resetForm();
      this.$emit("close");
    },

    removeFileError() {
      document.getElementById("file-error").style.display = "none";
    },

    addNewCertificateWindow(value) {
      this.certificatesModel = value;
      this.addingCertificateData = {
        caption: ""
      }
    },

    async addNewCertificate() {
      document.getElementById("file-error").style.display = "none";
      if (!this.$refs.certificateDetails.validate() || !this.$refs.images.files[0]) {
        if (!this.$refs.images.files[0]) {
          document.getElementById("file-error").style.display = "block";
        }
        return;
      }

      if(!validFilesSize(this.$refs.images.files)){
        notification.error("File size limit exceeded");
        return;
      }
      
      const file = this.$refs.images.files[0];
      const base64 = await this.toBase64(file);
      this.form.certificates.push({
        caption: this.addingCertificateData.caption,
        file: {
          name: file.name,
          size: file.size,
          base64: base64,
          extension: file.name.split(".").pop(),
          type: file.type,
        },
        sendToServer: true,
      });
      this.addNewCertificateWindow(false);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result)
        };
        reader.onerror = reject;
      });
    },

    async deleteCertificate(certificate) {
      if ( !confirm("Are you sure you want to delete this certification?") ) {
        return;
      }
      if (certificate.id) {
        await restAdapter.delete(
          `/api/inquiries/${this.inquiryId}/bom/other_supplier_certificate/${certificate.id}`
        );
        this.$emit('supplier-data-updated')
      }
      this.form.certificates = this.form.certificates.filter(
        (item) => item !== certificate
      );
    },
    resetForm() {
      this.form.supplierName = "";
      this.form.addressLine1 = "";
      this.form.addressLine2 = "";
      this.form.city = "";
      this.form.postalCode = "";
      this.form.state = "";
      this.form.country = "";
      this.form.certificates = [];
    },

    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      const supportedFileTypes = this.acceptedFileTypes.split(",");
      return supportedFileTypes.includes(extension.toLowerCase());
    },

    handleCertificateUpload(event) {
      const file = event.target.files[0];
      this.certificateFile = file;
    },
    validateCertificate() {
      if (this.certificateFile) {
        return "Certificate is required";
      }
      return true;
    },

    createOtherSupplier() {
      this.saving = true;
      if (!this.$refs.supplierDetails.validate()) {
        this.saving = false;
        return;
      }
      const payload = {
        bom_item_id: this.otherSupplierData.bomItemId,
        supplier_name: this.form.supplierName,
        address_line_1: this.form.addressLine1,
        address_line_2: this.form.addressLine2,
        city: this.form.city,
        postal_code: this.form.postalCode,
        state: this.form.state,
        country: this.form.country,
        certificates: this.form.certificates.filter(
          (certificate) => certificate.sendToServer
        ).map(certificate => ({
          ...certificate,
          file: {
            ...certificate.file,
            base64: certificate.file.base64
              .replace('data:', '')
              .replace(/^.+,/, '')
          }
        })),
      };

      if (!this.otherSupplierData.id) {
        restAdapter
          .post(`/api/inquiries/${this.inquiryId}/bom/other_supplier`, payload)
          .then((response) => {
            this.$emit("supplier-data-updated");
            notification.success("Supplier details created");
            this.resetForm();
            this.$emit("close");
          })
          .catch((error) => {
            this.saving = false;
            if (
              error.response &&
              error.response.status == 400 &&
              error.response.data &&
              error.response.data.error
            ) {
              notification.errors(error.response.data.error);
            } else {
              notification.error(
                "Something went wrong, please try again later."
              );
            }
          });
      } else {
        restAdapter
          .put(
            `/api/inquiries/${this.inquiryId}/bom/other_supplier`,
            {...payload, id: this.otherSupplierData.id}
          )
          .then((response) => {
            this.$emit("supplier-data-updated");
            notification.success("Supplier details updated");
            this.resetForm();
            this.$emit("close");
          })
          .catch((error) => {
            this.saving = false;
            if (
              error.response &&
              error.response.status == 400 &&
              error.response.data &&
              error.response.data.error
            ) {
              notification.errors(error.response.data.error);
            } else {
              notification.error(
                "Something went wrong, please try again later."
              );
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-content {
  margin-top: 15px;
}
.certificates-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.v-application--is-ltr .v-text-field .v-label {
  color: $primaryDark !important;
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: $primaryDark !important;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 0.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.close-btn {
  color: $tableBorder !important;
  background-color: $paginateText;
  border: 1px solid $tableBorder;
  border-radius: 3px;
  height: 40px;
}
.save-btn {
  color: #ffffff !important;
  background: #473068;
  border-radius: 3px;
  height: 40px;
  margin-right: unset;
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}
.certificate-window {
  margin-top: 15px;
}
.certificates-add-btn {
  width: fit-content;
  height: fit-content;
  padding: 1px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #473068;
  border-radius: 4px;
  cursor: pointer;
}
.certificates-details {
  padding: 15px;
  border: 1px solid #f6efff;;
  border-radius: 3px;
  margin-top: 10px;
  background-color: #f6efff;
  color: rgba(0, 0, 0, 0.3);
}
.certificates-details-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}
.centered-and-cropped {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.single-certificate {
  margin: 5px 10px 5px 0px;
}

.certificate-delete {
  cursor: pointer;
}

.file-error {
  display: none;
  color: #ff5252;
  font-size: 12px;
  margin-top: 5px;
  float: left;
  padding: 0px 12px;
}
</style>
<style>
.v-menu__content {
  z-index: 206 !important;
}
</style>
