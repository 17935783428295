<template>
  <div class="bom">
    <div class="bom-title">{{ title }}</div>
    <div class="image-container">
      <div class="image-child-container">
        <div style="position: relative;">
          <div v-if="!loading && !getIsSupplier()" @click="thumbnailOption()" class="more-vert" style="position: absolute; right: 0;">
            <v-icon class="more-vert-icon">more_vert</v-icon>
          </div>
          <div
            v-if="thumbnailOptionClicked && !getIsSupplier()"
            class="thumbnail-actions-container"
          >
            <label class="action-item">
              <input
                type="file"
                hidden
                @change="uploadThumbnail($event.target.files)"
              />Upload
            </label>
          </div>
          <embed
            v-if="!loading"
            :src="
              defaultImage && isSupportedFileType(defaultImage)
                ? `${baseUrl}/api/inquiries/file_show/${defaultImage}`
                : sampleImage
            "
            alt="sample image"
            class="image mt-1"
          />
          <div v-else>
            <md-progress-spinner
              class="color_stroke"
              md-mode="indeterminate"
            ></md-progress-spinner>
          </div>
        </div>
      </div>
    </div>
    <div class="breakdown-container">
      <cost-sheet-breakdowns :inquiryId="inquiryId" :showEdit="!getIsSupplier()"/>
    </div>
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
      <div class="last-update">Last Updated : {{ bomLastUpdate }}</div>
      <div v-if="!bulkBreakdown && !getIsSupplier()" style="text-align: left;">
        <v-btn
          class="breakdown-btn"
          @click="openTechPack"
          color="danger"
        >
          Go to Tech Pack
        </v-btn>
      </div>
    </div>
    <div class="table-container">
      <div class="table-content">
        <table class="bom-table">
          <thead id="bom-table-header" ref="bomTableHeader" class="table-header">
            <tr>
              <th
                :colspan="header.colspan"
                v-for="(header, index) in bomHeaders"
                :key="index"
                :class="`${
                  header.name == 'Approval' ? 'status-th' : 'other-th'
                }`"
              >
                {{ header.name }}
              </th>
              <td class="actions-td" :colspan="1"></td>
            </tr>
          </thead>
          <tbody v-if="tableLoading">
              <tr>
                <td colspan="21">
                  <md-progress-spinner
                    class="color_stroke"
                    md-mode="indeterminate"
                    style="margin: 10px; 0px;"
                  ></md-progress-spinner>
                </td>
              </tr>
          </tbody>
          <bom-table-body
            v-if="!tableLoading"
            :headerHeight="headerHeight"
            :inquiryId="inquiryId"
            :bomData="bomData.sewing_item"
            :category="categories[0]"
            :baseUrl="baseUrl"
            @refresh="getBomData"
            :measurements="measurements"
            :suppliers="suppliers"
            @refresh-log="refreshLog"
          />
          <bom-table-body
            v-if="!tableLoading"
            :headerHeight="headerHeight"
            :inquiryId="inquiryId"
            :bomData="bomData.label"
            :category="categories[1]"
            :baseUrl="baseUrl"
            @refresh="getBomData"
            :measurements="measurements"
            :suppliers="suppliers"
            @refresh-log="refreshLog"
          />
          <bom-table-body
            v-if="!tableLoading"
            :headerHeight="headerHeight"
            :inquiryId="inquiryId"
            :bomData="bomData.packing_item"
            :category="categories[2]"
            :baseUrl="baseUrl"
            @refresh="getBomData"
            :measurements="measurements"
            :suppliers="suppliers"
            @refresh-log="refreshLog"
          />
          <bom-table-body
            v-if="!tableLoading"
            :headerHeight="headerHeight"
            :inquiryId="inquiryId"
            :bomData="bomData.fabric"
            :category="categories[3]"
            :baseUrl="baseUrl"
            @refresh="getBomData"
            :measurements="measurements"
            :suppliers="suppliers"
            @refresh-log="refreshLog"
          />
          <bom-table-body
            v-if="!tableLoading"
            :headerHeight="headerHeight"
            :inquiryId="inquiryId"
            :bomData="bomData.other"
            :category="categories[4]"
            :baseUrl="baseUrl"
            @refresh="getBomData"
            :measurements="measurements"
            :suppliers="suppliers"
            @refresh-log="refreshLog"
          />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import CostSheetBreakdowns from "../../components/Inquiry/CostSheetBreakdowns.vue";
import restAdapter from "../../restAdapter";
import { bomDataBus } from "../../utils/bomDataBus";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import { isImageFile } from "../../utils/media-type";
import notification from "../../notification";
import auth from "../../auth";
import BomTableBody from "./BomTableBody.vue";
import routes from '../../router/routes';

export default {
  name: "BomComponent",
  components: {
    CostSheetBreakdowns,
    BomTableBody,
  },
  created() {
    bomDataBus.$on("last-update", this.updateLastUpdate);
    bomDataBus.$on('force-update', this.addData)
  },
  data: () => ({
    resizeObserver: null,
    inquiryId: "",
    baseUrl: "",
    bomData: {},
    title: "Bill of Material - BOM",
    defaultImage: null,
    sampleImage: require(`../../assets/shirt.png`),
    headerHeight: 0,
    bomHeaders: [
      { name: "Approval", colspan: 1 },
      { name: "Item", colspan: 2 },
      { name: "Image", colspan: 2 },
      { name: "Description", colspan: 3 },
      { name: "Measuring Unit", colspan: 2 },
      { name: "Consumption per garment", colspan: 2 },
      { name: "Total", colspan: 2 },
      { name: "Total Consumption", colspan: 2 },
      { name: "Supplier", colspan: 2 },
      { name: "Comments", colspan: 3 },
    ],
    // data: [],
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    bulkBreakdown: false,
    thumbnailOptionClicked: false,
    loading: false,
    tableLoading: false,
    bomLastUpdate: null,
    suppliers: [],
    measurements: [],
    categories: [
      {
        name : 'sewing_item',
        title : 'Sewing Item'
      },
      {
        name : 'label',
        title : 'Label'
      },
      {
        name : 'packing_item',
        title : 'Packing Item'
      },
      {
        name : 'fabric',
        title : 'Fabric'
      },
      {
        name : 'other',
        title : 'Other'
      },
    ],
  }),

  mounted() {
    this.loading = true;
    this.tableLoading = true;
    this.inquiryId = this.$route.params.id;
    this.baseUrl = conf.default.server.host;
    this.getBomData();
    this.getBreakdowns();
    this.getMeasurements();
    this.getSupplierDetails();
    this.observeHeaderHeight();
  },

  methods: {
    observeHeaderHeight() {
      const resizeObserver = new ResizeObserver(() => {
        this.headerHeight = document.getElementById('bom-table-header').offsetHeight;
      });

      resizeObserver.observe(document.getElementById('bom-table-header'));
      this.resizeObserver = resizeObserver;
    },

    updateLastUpdate(lastUpdate) {
      this.bomLastUpdate = lastUpdate;
    },

    refreshLog() {
      bomDataBus.$emit("refresh-log");
    },

    getBomData() {
      this.refreshLog();
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/bom`)
        .then((response) => {
          this.bomData = response.data.bom_data;
          this.defaultImage = response.data.default_image;
          this.loading = false;
          this.tableLoading = false;
        });
    },

    getIsSupplier() {
      return auth.user.userType === "supplier";
    },

    getBreakdowns() {
      restAdapter
        .get(`/api/costing/show_breakdown/${this.inquiryId}`)
        .then((response) => {
          this.bulkBreakdown = response.data.bulkBreakdowns.length
            ? true
            : false;
        })
        .catch((error) => {
          if(error.response.status === 401){
            this.$router.push({name: routes.COLLECTION_LIST});
          }
        });
    },

    getMeasurements() {
      restAdapter.get(`/api/unit_of_measurements`).then((response) => {
        this.measurements = response.data;
      });
    },

    getSupplierDetails() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/assigned_suppliers`)
        .then((response) => {
          this.suppliers = response.data.inquiry_assigned_suppliers;
        });
    },

    openTechPack() {
      this.$router.push({ name: "Tech Pack Development" });
    },

    isImage(file) {
      return isImageFile(file);
    },

    thumbnailOption() {
      this.thumbnailOptionClicked = this.thumbnailOptionClicked ? false : true;
    },

    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },

    uploadThumbnail(file) {
      this.loading = true;
      this.thumbnailOptionClicked = false;
      if (file.length !== 0) {
        const formData = new FormData();
        formData.append("file[]", file[0]);
        restAdapter
          .post("/api/inquiries/files_upload/" + this.inquiryId, formData)
          .then((response) => {
            this.thumbnailOptionClicked = false;
            this.setDefaultImage(response.data.attachment_id);
          })
          .catch((error) => {
            notification.error("Something went wrong.");
          });
      }
    },

    setDefaultImage(id) {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_default_image/`, {
          default_image: id,
        })
        .then(() => {
          notification.success("Image set as default thumbnail successfully");
          this.loading = false;
          this.defaultImage = id;
        });
    },
  },

  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.bom {
  min-height: 75vh;
}

.bom-title {
  text-align: center;
  font-size: 25px;
  background-color: #473068;
  color: #fff;
  font-weight: bold;
  padding: 10px 0px;
}

.image-container {
  align-items: center;
  margin: 25px 0px;
}

.image-child-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  justify-content: space-between;
}

.breakdown-container {
  margin: 25px 5px;
}

.breakdown-btn {
  background-color: #ea7a66;
  color: #fff;
  border-radius: 5px;
  margin-left: 5px;
}

.image {
  object-fit: cover;
  height: 140px;
  width: 150px;
}

.last-update {
  text-align: left;
  background-color: #eef1fa;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  margin-left: 5px;
  padding: 2px 10px;
  color: #473068;
  font-size: 18px;
}

.table-container {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 35px;
}

.table-content {
  margin: 25px 5px 15px 5px;
  overflow-y: auto;
  max-height: 90vh;
}

.bom-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 6;
}

.other-th {
  outline: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #47c0be;
  color: #442d65;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 3px;
  word-wrap: break-word;
}

.status-th {
  outline: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #473068;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 3px;
  word-break: break-all;
}

.actions-td {
  border: none !important;
  width: 60px !important;
  background-color: #fff;
}

.more-vert {
  margin: -5px;
  background: $paginateText;
  width: 20px;
  height: 20px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}

.more-vert:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}

.thumbnail-actions-container {
  right: 20px;
  top: 20px;
  width: 90px;
  text-align: left;
  padding: 5px;
  height: auto;
  z-index: 8;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.action-item {
  cursor: pointer;
  width: 100%;
  display: block;
}

.action-item:hover {
  background-color: white;
  color: #442d64;
}

@media (max-width:1024px) {
  .bom-table {
    table-layout: auto;
  }
}
</style>
