<template>
  <tbody>
    <tr class="sub-header" :style="`top: ${headerHeight}px;};`">
      <td colspan="21" class="category-title">
        {{ category.title }}
      </td>
    </tr>
    <tr
      v-for="(data, index) in bomData"
      v-bind:key="index"
      :class="index % 2 == 1 ? 'odd-row' : 'even-row'"
    >
      <td
        class="table-status-data"
        :class="index % 2 == 1 ? 'odd-status-row' : 'even-status-row'"
        :colspan="1"
      >
        <div class="td-container">
          <v-checkbox
            v-model="data.approve_status"
            @click="itemApproval(data.id)"
            color="#47C0BE"
            class="checkbox"
            :disabled="getIsSupplier()"
          />
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container">
          <v-textarea
            v-model="data.name"
            auto-grow
            rows="1"
            row-height="1"
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
            maxlength="1000"
          ></v-textarea>
        </div>
      </td>
      <td class="table-data table-images-data" :colspan="2">
        <div :id="`files-cell-${data.id}`" style="position: relative;">
          <label :for="`chooseFiles` + data.id" v-if="!getIsSupplier()">
            <div class="file-input">Choose files</div>
          </label>
          <input
            :id="`chooseFiles` + data.id"
            type="file"
            style="display: none"
            ref="file"
            multiple="multiple"
            :accept="acceptedFileTypes"
            @change="onFileChange($event, data.id)"
          />
          <div :ref="`progress-container-${data.id}`" class="progress-container" style="display: none;">
            <div :ref="`progress-${data.id}`" class="progress-bar" style="width: 0%"></div>
          </div>
          <v-row class="mt-3 data-field">
            <v-col
              cols="12"
              style="padding: 8px 0px;"
              v-for="file in data.files"
              v-bind:key="file.id"
            >
              <div style="border: 0.5px solid #442d64;" @click="handlePreview(isImage(file) ? file : null)">
                <div
                  class="image-size"
                  :style="
                    isImage(file)
                      ? `background-image: url(${baseUrl}/api/inquiries/file_show/${file.upload_name});`
                      : `background-image: url(${imageFile});`
                  "
                >
                  <div :id="`more-vert-${file.id}`" @click.stop="options(file.id, data.id)" class="more-vert">
                    <v-icon class="more-vert-icon">more_vert</v-icon>
                  </div>
                  <div class="file-size">
                    {{
                      file.file_size > 1000
                        ? (file.file_size / 1024).toFixed(2) + " " + "Mb"
                        : file.file_size + " " + "Kb"
                    }}
                  </div>
                </div>
                <div class="file-name">
                  <truncate-hovering
                    :text="fileTitle(file.title)"
                    :length="5"
                    :appendText="'.' + fileExtension(file.title)"
                    :displayAppendOnHover="true"
                  />
                </div>
              </div>
              <div :id="`files-action-container-${file.id}`" class="actions-container">
                  <div class="actions-list" @click="downloadFile(file)">
                    Download
                  </div>
                  <div
                    class="actions-list"
                    @click="fileRename(file)"
                    v-if="!getIsSupplier()"
                  >
                    Rename
                  </div>
                  <div
                    class="actions-list"
                    @click="setMainImage(file.upload_name,data.name)"
                    v-if="!getIsSupplier() && isImage(file)"
                  >
                    Set as main image
                  </div>
                  <div
                    class="actions-list"
                    @click="deleteFile(file)"
                    v-if="!getIsSupplier()"
                  >
                    Delete
                  </div>
                </div>
            </v-col>
          </v-row>
        </div>
      </td>
      <td class="table-data" :colspan="3">
        <div class="td-container">
          <v-textarea
            auto-grow
            rows="1"
            row-height="15"
            v-model="data.description"
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
          ></v-textarea>
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container">
          <v-autocomplete
            :items="measurements"
            item-text="measurement_name"
            item-value="id"
            v-model="data.measuring_unit"
            label=""
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
          >
          </v-autocomplete>
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container">
          <v-text-field
            v-model="data.consumption_per_garment"
            type="number"
            min="0"
            step="0.01"
            @keypress="isValidDecimal($event, data.consumption_per_garment)"
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
          ></v-text-field>
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container">
          <v-text-field
            v-model="data.total_quantity"
            type="number"
            min="0"
            step="1"
            @keypress="isNumberKey($event)"
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
          ></v-text-field>
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container calculated-total">
          <div
            v-if="data.consumption_per_garment && data.total_quantity"
            style="text-align: left; margin-top: 6px;"
          >
            {{ (data.consumption_per_garment * data.total_quantity).toFixed(2) }}
          </div>
        </div>
      </td>
      <td class="table-data" :colspan="2">
        <div class="td-container">
          <div class="supplier-container mb-2">
            <v-autocomplete
              v-model="data.suppliers"
              :items="suppliers"
              :item-text="(item) => item.brand_name ? item.name_full + ` (${item.brand_name})` : item.name_full"
              item-value="supplier_id"
              no-data-text="No suppliers left to assign"
              label=""
              multiple
              :disabled="getIsSupplier()"
              @input="debounceBomData()"
              style="margin-bottom: -10px"
            >
            </v-autocomplete>
          </div>
          <div
            v-if="data.suppliers.length"
            class="supplier-data-container mb-4"
          >
            <div
              class="supplier-data"
              v-for="(supplier, index) in data.suppliers"
              v-bind:key="index"
            >
              <div v-if="suppliers.find((sup) => sup.supplier_id == supplier)" class="supplier-chip">
                <div style="width: 100%;">
                  <div
                    v-if="
                      suppliers.find((sup) => sup.supplier_id == supplier)
                        .brand_name
                    "
                    class="supplier-name"
                  >
                    {{
                      suppliers.find((sup) => sup.supplier_id == supplier)
                        .brand_name
                    }}
                  </div>
                  <div v-else class="supplier-name">
                    {{
                      suppliers.find((sup) => sup.supplier_id == supplier)
                        .name_full
                    }}
                  </div>
                </div>
                <div v-if="!getIsSupplier()" class="supplier-close" @click="
                    data.suppliers.splice(index, 1);
                    debounceBomData();
                  "><v-icon style="font-size: 12px; color: #47c0be;">close</v-icon>
                </div>
              </div>
            </div>
          </div>
          <div style="font-size: 12px; text-align: left;">Other suppliers</div>
          <div
            v-if="data.other_suppliers.length"
            class="supplier-data-container mb-4"
          >
            <div
              class="supplier-data"
              v-for="(otherSupplier, index) in data.other_suppliers"
              v-bind:key="index"
            >
              <div  class="other-supplier-chip">
                <div style="width: 100%;">
                  <div
                    class="supplier-name"
                  >
                  {{
                      otherSupplier.supplier_name
                    }}
                  </div>
                </div>
                <div v-if="!getIsSupplier()" class="other-supplier-chip-actions">
                  <div>
                    <v-icon class="other-supplier-chip-edit" @click="switchOtherSupplierEditMode(otherSupplier)">edit</v-icon>
                  </div>
                  <div>
                    <v-icon class="other-supplier-chip-delete" @click="deleteOtherSupplier(otherSupplier.id)">delete</v-icon>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="tooltip mt-2 mb-8" v-if="!getIsSupplier()">
            <v-btn
              @click="openAddOtherSupplierWindow(data.id)"
              class="add-remove-btn"
              x-small
              color="#70D0CE"
              >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </div>
        </div>
      </td>
      <td class="table-data" :colspan="3">
        <div class="td-container">
          <v-textarea
            v-model="data.comments"
            auto-grow
            rows="1"
            row-height="15"
            :disabled="getIsSupplier()"
            @input="debounceBomData()"
          ></v-textarea>
        </div>
      </td>
      <td class="actions-td" v-if="!getIsSupplier()" :colspan="1">
        <div class="tooltip ml-1">
          <v-btn
            @click="removeRow(data.id)"
            class="add-remove-btn"
            x-small
            color="#EA7A66"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
          <span class="tooltiptext tooltip-left mt-3">Remove Record</span>
        </div>
      </td>
    </tr>
    <div class="tooltip mt-2 mb-8" v-if="!getIsSupplier()">
      <v-btn
        @click="addRow(category.name)"
        class="add-remove-btn"
        x-small
        color="#70D0CE"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <span class="tooltiptext tooltip-right mt-3">Add New Record</span>
    </div>
    <popup-window
      :active="showPreview"
      :fullScreen="true"
      maxWidth="fit-content"
      width="fit-content"
      @popup-close="handlePreview()"
      @popup-back="handlePreview()"
    >
      <div>
        <img :src="previewingFile" style="max-width: 500px;" />
      </div>
    </popup-window>
    <popup-window
      :active="showRenameForm"
      :hasClose="false"
      noMargin
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div class="rename-form">
        <div class="rename-title">Rename file name to:</div>
        <input
          v-model="newFileName"
          class="rename-input"
          type="text"
          placeholder="Rename file name"
          id="rename"
          name="rename"
        />
        <div class="rename-room-buttons">
          <ui-button
            class="primary short space"
            @click="setNewFileName"
            title="✔ Ok"
          />
          <ui-button class="lighter short" @click="resetForms" title="Cancel" />
        </div>
      </div>
    </popup-window>
    <popup-window
      :active="addOtherSupplier"
      :hasClose="true"
      title="Other Supplier"
      @popup-close="closePopUp()"
      @popup-back="closePopUp()"
    >
      <BomOtherSupplier
        v-if="addOtherSupplier"
        :otherSupplierData="otherSupplierData"
        :inquiryId="inquiryId"
        @supplier-data-updated="$emit('refresh')"
        @close="closePopUp"
      />
    </popup-window>
  </tbody>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import auth from "../../auth";
import restAdapter from "../../restAdapter";
import { isImageFile } from "../../utils/media-type";
import { bomDataBus } from "../../utils/bomDataBus";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import debounce from "lodash/debounce";
import notification from "../../notification";
import { validFilesSize } from "../../utils/file-size";
import { isNumberKey, isDecimalKey } from "../../utils/input-key";
import BomOtherSupplier from "../Inquiry/BomOtherSupplier.vue";

export default {
  name: "BomTableBody",
  components: {
    BomOtherSupplier,
  },
  created() {
    bomDataBus.$on("force-update", this.addData);
  },
  data: () => ({
    imageFile: require(`../../assets/folder.png`),
    actionId: null,
    acceptedFileTypes: utils.acceptedFileTypes,
    showRenameForm: false,
    newFileName: null,
    renameFile: null,
    showPreview: false,
    previewingFile: '',
    addOtherSupplier:false,
    bomItemId : null,
    activeActionsId:"",
    isNew:false,
    otherSupplierData:{},
  }),

  props: {
    headerHeight: {
      type: Number,
      default: 0,
    },
    inquiryId: {
      type: String,
      required: true,
    },
    bomData: {
      type: Array,
      default: () => [],
    },
    baseUrl: {
      type: String,
      default: conf.default.server.host,
    },
    category: {
      type: Object,
      required: true,
    },
    measurements: {
      type: Array,
      default: () => [],
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    isNumberKey(evt) {
      if(isNumberKey(evt)) return true;
      return evt.preventDefault();
    },

    isValidDecimal(evt, value) {
      if (isDecimalKey(evt, value)) return true;
      return evt.preventDefault();
    },

    getIsSupplier() {
      return auth.user.userType === "supplier";
    },

    isImage(file) {
      return isImageFile(file);
    },

    async addRow(name) {
      await this.addData();
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/bom`, { category: name })
        .then((response) => {
          this.$emit("refresh");
        });
    },

    async removeRow(id) {
      if (confirm("Remove this row?")) {
        await this.addData();
        restAdapter
          .delete(`/api/inquiries/${this.inquiryId}/delete_bom_item/${id}`)
          .then((response) => {
            this.$emit("refresh");
          });
      }
    },

    options(id, cell) {
      this.resetActionContainers();
      this.actionId = this.actionId == id ? null : id;
      if(this.actionId){
        const element = document.getElementById(`more-vert-${id}`);
        const container = document.getElementById(`files-action-container-${id}`);
        const bodyRect = document.getElementById(`files-cell-${cell}`).getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();
        container.style.top = `${(elemRect.top - bodyRect.top) + 20}px`;
        container.style.display = 'inherit';
      }
    },

    handlePreview(file) {
      if(!file){
        this.showPreview = false;
        this.previewingFile = '';
      } else {
        this.showPreview = true;
        this.previewingFile = `${conf.server.host}/api/inquiries/file_show/${file.upload_name}`;
      }
    },

    downloadFile(file) {
      window.open(
        `${conf.server.host}/api/inquiries/file_download/${file.upload_name}`
      );
    },

    async deleteFile(file) {
      if (confirm("Delete this file?")) {
        await this.addData();
        restAdapter
          .delete(
            `/api/inquiries/${this.inquiryId}/bom_file_delete/${file.upload_name}`
          )
          .then(() => {
            notification.success("File deleted successfully");
            this.$emit("refresh");
          });
      }
    },

    resetForms() {
      this.showRenameForm = false;
      this.newFileName = null;
      this.renameFile = null;
      this.actionId = null;
      this.resetActionContainers();
    },

    resetActionContainers() {
      document.querySelectorAll('[id^=files-action-container]').forEach((el) => {
        el.style.display = 'none';
      });
    },

    fileTitle(title) {
      return title.split(".")[0];
    },

    fileExtension(title) {
      return title.split(".").pop();
    },

    fileRename(file) {
      this.renameFile = file;
      this.showRenameForm = true;
    },

    async setNewFileName() {
      await this.addData();
      restAdapter
        .put(
          `/api/inquiries/${this.inquiryId}/bom_file_rename/${this.renameFile.upload_name}`,
          { new_name: this.newFileName }
        )
        .then(() => {
          notification.success("File renamed successfully");
          this.resetForms();
          this.$emit("refresh");
        });
    },

    refreshLog() {
      this.$emit("refresh-log");
    },

    async addData() {
      const response = await restAdapter.put(
        `/api/inquiries/${this.inquiryId}/bom`,
        {
          bom_data: {
            [this.category.name]: this.bomData,
          },
        }
      );
      this.refreshLog();
      return response;
    },

    debounceBomData: debounce(async function () {
      this.addData();
    }, 5000),

    itemApproval(id) {
      restAdapter
        .put(`/api/inquiries/${this.inquiryId}/approve_bom_item/${id}`)
        .then((response) => {
          this.refreshLog();
          notification.success("Status Updated Successfully");
        });
    },

    async onFileChange(event, bomId) {
      const files = event.target.files
      if(!validFilesSize(files)){
        notification.error("Files size limit exceeded");
        return;
      }
      const container = this.$refs[`progress-container-${bomId}`][0];
      const progressBar = this.$refs[`progress-${bomId}`][0];
      container.style.display = "inherit";
      progressBar.style.width = "0%";

      let progress = 0;
      await this.addData();
      if (files.length !== 0) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formData.append("file[" + i + "]", file);
        }
        event.target.value = '';
        restAdapter
          .post(
            `/api/inquiries/${this.inquiryId}/bom_file_upload/${bomId}`,
            formData,
            {
              onUploadProgress: (progressEvent) => {
                progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                progressBar.style.width = `${progress}%`;
              },
            }
          )
          .then(() => {
            container.style.display = "none";
            progressBar.style.width = "0%";
            notification.success("File Uploaded Successfully");
            this.$emit("refresh");
          })
          .catch((error) => {
            container.style.display = "none";
            progressBar.style.width = "0%";
            if(error.response?.data?.message){
              notification.error(error.response.data.message);
            } else {
              notification.error("Something went wrong.");
            }
          });
      }
    },

    async fileDelete() {
      await this.addData();
      restAdapter
        .delete(
          `/api/inquiries/${this.inquiryId}/bom_file_delete/${this.unique_file_id}`
        )
        .then((response) => {
          this.resetForms();
          notification.success("File Deleted Successfully");
        });
    },
    openActions(id,event){
      event.stopPropagation();
      if(this.activeActionsId === id){
        this.activeActionsId = ""
      } else {
        this.activeActionsId = id
      }
    },
    deleteOtherSupplier(id){
      if (!confirm("Delete this Other Supplier?")) {
        return;
      }

      restAdapter
        .delete(
          `/api/inquiries/${this.inquiryId}/bom/other_supplier/${id}`
        )
        .then((response) => {
          this.$emit("refresh");
          notification.success("Other Supplier Deleted Successfully");
        });
    },
    switchOtherSupplierEditMode(otherSupplierData){
      this.otherSupplierData = {
        id: otherSupplierData.id,
        bomItemId: otherSupplierData.bom_item_id,
        supplierName: otherSupplierData.supplier_name,
        addressLine1: otherSupplierData.address_line_1 ?? '',
        addressLine2: otherSupplierData.address_line_2 ?? '',
        city: otherSupplierData.city ?? '',
        state: otherSupplierData.state ?? '',
        country: otherSupplierData.country ?? '',
        postalCode: otherSupplierData.postal_code ?? '',
        certificates: otherSupplierData.certifications ?? []
      };
      this.addOtherSupplier = true;

    },
    openAddOtherSupplierWindow(id){
      this.otherSupplierData = {
        bomItemId: id,
        supplierName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        certificates: [],
      };
      this.addOtherSupplier = true;
      this.bomItemId = id;
      this.isNew = true;
    },
    getSupplierData(supplierData){
      this.supplierData = supplierData.bom_other_supplier;
    },
    closePopUp() {
      this.addOtherSupplier=false;
      this.otherSupplierData = {};
    },
    setMainImage(file,name) {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_main_image/`, {
          main_image: file, name: name,
        })
        .then(() => {
          notification.success("Image set as main image successfully");
          this.refreshLog();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-header {
  position: sticky;
  z-index: 5;
}

.actions-td {
  border: none !important;
  width: 60px !important;
  background-color: #fff;
}

.checkbox::v-deep .v-icon.v-icon {
  font-size: 30px;
}

.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}

.checkbox::v-deep .v-input--selection-controls__input {
  margin: auto !important;
}

.table-data {
  border: 0.5px solid #473068;
  text-align: center;
  color: #473068;
  font-weight: 600;
  font-size: 15px;
  padding: 10px 3px;
  vertical-align: top;
}

.table-status-data {
  border: 0.5px solid #473068;
  text-align: center;
  color: #473068;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 3px;
  overflow: auto;
  width: 5%;
  vertical-align: top;
}

.table-images-data {
  width: 2vw;
}

.file-input {
  font-size: 14px;
  padding: 2px 4px;
  border: 1px solid #442d64;
  border-radius: 8px;
  cursor: pointer;
}

.file-input:hover {
  background-color: rgba(211, 211, 211, 0.5);
}

.odd-row {
  background-color: #faf7ff;
}

.even-row {
  background-color: #fff;
}

.other-supplier-chip-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.odd-status-row {
  background-color: #faf7ff;
}

.even-status-row {
  background-color: #fff;
}

.table-status-data::v-deep .v-input__slot {
  justify-content: center !important;
}

.image-data-container {
  border: 0.5px solid #442d64;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
  width: 150px;
  margin: -6px 10px;
}

.image-container {
  position: relative;
  width: 25px;
  border-radius: 5px; filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.action-container {
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 8px;
  height: auto;
  overflow: visible;
  z-index: 100;
  background: rgb(255, 255, 255);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
}

.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}

.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}

.more-vert:hover {
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
}

.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}
.image-size {
  position: relative;
  margin-bottom: 2px;
  width: 100%;
  min-height: 70px;
  aspect-ratio: 1/1;
  background-size: cover;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert {
  position: absolute;
  top: 0;
  right: 0;
  background: $paginateText;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}

.more-vert:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-icon {
  font-size: 15px;
  vertical-align: unset;
}

.actions-container {
  display: none;
  width: 120px;
  height: auto;
  z-index: 8;
  position: absolute;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.actions-list {
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
  padding: 6px 10px;
  z-index: 8;
}

.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}

.file-size {
  background-color: white;
  bottom: 0;
  position: absolute;
  right: 0;
  padding: 1px 5px;
  font-size: 12px;
}

.file-name {
  color: #442d65;
  font-size: 12px;
  word-break: break-all;
}

.file-date {
  color: #442d65;
  font-size: 13px;
  font-weight: 400;
  padding: 0px 15px;
  margin-bottom: 3px;
  word-break: break-all;
}

.td-container {
  max-height: 60vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 0 15px 0;
  border-radius: 7px;
}

.data-field {
  max-height: 56vh;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 0 15px 0;
  border-radius: 7px;
}

.supplier-container::v-deep .v-select__selection--comma {
  display: none;
}

.supplier-data-container {
  padding-bottom: 5px;
}

.supplier-name {
  font-size: 12px;
  width: 100%;
  color: white;
}

.tooltip {
  float: left;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #faf7ff;
  color: #473068;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  border: 1px solid #473068;
}

.tooltip-left {
  top: -11px;
  bottom: auto;
  right: 115%;
}

.tooltip-right {
  top: -11px;
  left: 100%;
}

.tooltip:hover .tooltiptext {
  transition-delay: 1s;
  visibility: visible;
}

.add-remove-btn {
  height: 32px !important;
  width: 31px !important;
  box-shadow: none;
  border-radius: 3.99038px;
}

.chip-data {
  margin: 2px !important;
}

.v-text-field {
  padding-top: unset !important;
  margin-top: unset !important;
}

.rename-form {
  width: 70%;
  margin: auto;
}

.rename-title {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.rename-input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid $primaryDark;
  color: $spinnerStroke;
}

input:focus {
  outline: none;
}

::placeholder {
  color: $primaryDark;
  font-weight: 400;
}

.rename-room-buttons {
  padding: 30px 0 15px 0;
}

.category-title {
  padding: 4px;
  text-align: left;
  font-size: 17px;
  font-weight: bold;
  color: #473068;
  background: #eef1fa;
}

.progress-container {
  width: 100%;
  height: 5px;
  background-color: rgba(68,138,255,0.3);
  margin: 5px 0px;
}

.progress-bar {
  height: 100%;
  background-color: #492A67;
  text-align: center;
}

.td-container::v-deep input {
  font-size: 15px;
}

.td-container::v-deep textarea {
  font-size: 15px;
}

.supplier-chip {
  display: flex;
  background-color: #47c0be;
  border-radius: 5px;
  flex-direction: row;
  margin: 5px 0px;
  padding: 3px;
  min-height: 18px;
  justify-content: space-between;
}

.other-supplier-chip {
  background-color: #47c0be;
  border-radius: 5px;
  margin: 5px 0px;
  padding: 3px;
  min-height: 18px;
}

.other-supplier-chip-edit {
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.other-supplier-chip-delete {
  color: $danger;
  font-size: 14px;
  cursor: pointer;
}

.supplier-close {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: #473068;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.calculated-total {
  overflow-x: auto;
}

.calculated-total::-webkit-scrollbar {
  display: none;
}

.calculated-total {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
